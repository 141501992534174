import * as React from "react";
import Button from './Button';
import {StaticImage} from "gatsby-plugin-image";


const HomeHero = () => {

  return (
    <div className={'relative pt-40 lg:pt-56 pb-24 lg:pb-48 overflow-hidden'}>
      <div className="absolute inset-0 bg-black pointer-events-none -z-10" aria-hidden="true">
        <div className="w-full h-full" data-aos={"fade"}>
          <StaticImage
            className={"opacity-20 w-full h-full object-cover"}
            src={'../images/bg.jpg'} alt={'Hero'}
          />
        </div>
      </div>

      <div className={'flex flex-col items-center justify-center px-4 md:px-8'}>

        <h1 className={'max-w-2xl text-white text-center text-4xl sm:text-5xl md:text-6xl uppercase tracking-tight leading-20 font-bold mb-4'}>A contemporary & welcoming hair salon</h1>
        <h2 className={'text-gray text-xl uppercase mb-8'}>Based in Eaton, Norwich</h2>

        <div className={`flex flex-col lg:flex-row justify-center mt-4 gap-2`}>
          <Button className={`bg-red-700 border-red-700 hover:bg-red-500 text-white`}
                  path={'/our-salon'}
                  text={'Our Salon'}
          />
          <Button className={`bg-red-700 border-red-700 hover:bg-red-500 text-white`}
                  path={'/booking'}
                  text={'Make A Booking'}
          />
        </div>

      </div>

    </div>
  )

}


export default HomeHero;