import * as React from "react";
import { Link } from "gatsby";

const TextLink = ({ href, text, className, target = '' }) => {

  return (

    <Link to={href}
          className={`text-red transition ease-in-out duration-300 hover:text-red-600 hover:underline ${ className }`}
          target={target}
    >
      { text }
    </Link>

  );

}

export default TextLink;