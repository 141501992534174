import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "./Button";

const HomeIntro = () => {

  return (

    <section className={'relative'}>
      <div className={'relative max-w-6xl mx-auto px-6 sm:px-8'}>
        <div className={'py-16 md:py-20'}>

          {/* Section content */}
          <div className="max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:items-center md:space-x-8 lg:space-x-16 xl:space-x-18 space-y-8 space-y-reverse md:space-y-0">

            {/* Content */}
            <div className="md:w-7/12 lg:w-1/2 order-1 md:order-none space-y-4" data-aos={"fade"}>

              <h2 className={'text-3xl md:text-4xl font-bold uppercase mb-4'}>
                <span className={'text-xl block font-normal text-gray uppercase leading-none'}>Welcome to</span> Adrian Rowe Haircutters<span className={'text-red'}>.</span>
              </h2>

              <p className={'font-medium text-lg'}>
                Established in 1988, Adrian Rowe Haircutters is one of Norwich’s longest established and respected hair salons,
                covering all aspects of unisex hairdressing.
              </p>

              <p className={'font-light'}>
                 Whether you are a new or a returning client, it is our passion to provide you
                and your hair with the ultimate 5* treatment!
              </p>

              <p className={`font-light`}>
                All of our hair styling team are trained to the highest standards and regularly attend higher training courses,
                to stay up-to-date with all of the latest hair cutting, hair styling and hair colouring techniques.
              </p>

              <div className={'flex flex-row'}>
                <Button text={'services'} path={'/services'} className={`mr-2`}/>
                <Button text={'Price List'} path={'/pricelist'}/>
              </div>

            </div>

            {/* Image */}
            <div className="md:w-5/12 lg:w-1/2" data-aos={"fade"} data-aos-delay={"400"}>

              <div className="relative">
                <div className="absolute inset-0 pointer-events-none border-2 border-gray-50 mt-3 ml-3 translate-x-4 translate-y-4 -z-10" aria-hidden="true"/>
                <StaticImage className={`mx-auto md:max-w-none`} src={"../images/home-1.jpg"} alt={"Adrian Rowe Haircutters"}/>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>

  );

}

export default HomeIntro;