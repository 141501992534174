import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";

const HomeBlocks = () => {

  return (
    <section className={`bg-gray-50`}>
      <div className="max-w-7xl mx-auto px-6 sm:px-8">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-4xl mx-auto text-center pb-8" data-aos={"fade"}>
            <h2 className={`text-4xl uppercase text-black font-bold`}>
              Style<span className={`text-red`}>.</span> Quality<span className={`text-red`}>.</span> Professionalism<span className={`text-red`}>.</span>
            </h2>
            <h2 className={`text-4xl uppercase text-black font-bold mb-4`}>Triple Satisfaction<span className={`text-red`}>.</span></h2>

            <p className={`text-xl`}>
              We strive to provide the highest quality salon experience to everyone who steps through our doors.
            </p>
          </div>


          {/* Section content */}
          <div className="max-w-sm mx-auto md:max-w-none grid gap-12 md:grid-cols-3 md:gap-x-10 md:gap-y-10 items-start">

            {/* Our Salon */}
            <div data-aos={"fade"} data-aos-delay={"200"}>
              <Link className={`relative block group mt-8 mb-4`} to={"/our-salon"}>
                <div className={`absolute inset-0 pointer-events-none border-2 border-white translate-x-4 -translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-300 ease-out -z-10" aria-hidden="true`}/>
                <div className={`relative overflow-hidden`}>
                  <div className={`h-8 absolute top-1/2 left-0 w-full flex flex-row justify-center items-center -mt-4 z-20`}>
                    <h3 className={`text-white uppercase text-3xl font-bold leading-none`}>Our Salon</h3>
                    <svg className="w-10 h-10 text-red ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                    </svg>
                  </div>

                  <div className={`absolute h-full w-full bg-gradient-to-t from-transparent via-black to-transparent opacity-30 z-10`}/>

                  <StaticImage
                    className="w-full aspect-square object-cover group-hover:scale-105 transition duration-700 ease-out"
                    src={'../images/home-salon.jpg'}
                    alt={'Adrian Rowe Haircutters'}
                  />
                </div>
              </Link>
            </div>

            {/* Services */}
            <div data-aos={"fade"} data-aos-delay={"400"}>
            <Link className={`relative block group mt-8 mb-4`} to={'/services'}>
              <div className={`absolute inset-0 pointer-events-none border-2 border-white translate-x-4 -translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-300 ease-out -z-10" aria-hidden="true`}/>
              <div className={`relative overflow-hidden`}>
                <div className={`h-8 absolute top-1/2 left-0 w-full flex flex-row justify-center items-center -mt-4 z-20`}>
                  <h3 className={`text-white uppercase text-3xl font-bold leading-none`}>Services</h3>
                  <svg className="w-10 h-10 text-red ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                  </svg>
                </div>

                <div className={`absolute h-full w-full bg-gradient-to-t from-transparent via-black to-transparent opacity-30 z-10`}/>

                <StaticImage
                  className="w-full aspect-square object-cover group-hover:scale-105 transition duration-700 ease-out"
                  src={'../images/home-services.jpg'}
                  alt={'Adrian Rowe Services'}
                />
              </div>
            </Link>
            </div>


            {/* Make A Booking */}
            <div data-aos={"fade"} data-aos-delay={"600"}>
              <Link className={`relative block group mt-8 mb-4`} to={"/booking"}>
                <div className={`absolute inset-0 pointer-events-none border-2 border-white translate-x-4 -translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-300 ease-out -z-10" aria-hidden="true`}/>
                <div className={`relative overflow-hidden`}>
                  <div className={`h-8 absolute top-1/2 left-0 w-full flex flex-row justify-center items-center -mt-4 z-20`}>
                    <h3 className={`text-white uppercase text-3xl font-bold leading-none`}>Make A Booking</h3>
                    <svg className="w-10 h-10 text-red ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                    </svg>
                  </div>

                  <div className={`absolute h-full w-full bg-gradient-to-t from-transparent via-black to-transparent opacity-30 z-10`}/>

                  <StaticImage
                    className="w-full aspect-square object-cover group-hover:scale-105 transition duration-700 ease-out"
                    src={'../images/home-booking.jpg'}
                    alt={'Adrian Rowe Haircutters'}
                  />
                </div>
              </Link>
            </div>

          </div>

        </div>
      </div>
    </section>
  );

}

export default HomeBlocks;