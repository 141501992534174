import * as React from "react"
import TextLink from "./TextLink"

const LocationOpeningTimes = () => {
  const openingTimes = [
    { day: "Monday", time: "Closed" },
    { day: "Tuesday", time: "9:00AM - 5:30PM" },
    { day: "Wednesday", time: "9:00AM - 5:30PM" },
    { day: "Thursday", time: "9:00AM - 7:30PM" },
    { day: "Friday", time: "9:00AM - 7:30PM" },
    { day: "Saturday", time: "9:00AM - 5:30PM" },
    { day: "Sunday", time: "Closed" },
  ]

  return (
    <section className={"relative"}>
      <div className={"relative max-w-6xl mx-auto px-6 sm:px-8"}>
        <div className={"py-16 md:py-20"}>
          <div
            className="max-w-xl mx-auto md:max-w-none flex flex-col md:flex-row md:items-start md:space-x-12 lg:space-x-20 xl:space-x-24 space-y-8 space-y-reverse md:space-y-0"
            data-aos={"fade"}
          >
            {/* Find Us / Address */}
            <div className="md:w-7/12 order-1 md:order-none">
              <h3 className={`text-3xl text-black font-bold uppercase mb-4`}>
                Find Us<span className={`text-red`}>.</span>
              </h3>

              <h4 className={`text-xl text-black font-bold uppercase`}>
                Directions<span className={`text-red`}>:</span>
              </h4>
              <p className={`font-light mb-8`}>
                Traveling along Bluebell Road, turn into Norvic Drive and follow
                the road round to the left. You will see a large free car park
                on your right. Our salon is situated along the row of shops
                below the residential area.
              </p>

              <h4 className={`text-xl text-black font-bold uppercase`}>
                Address<span className={`text-red`}>:</span>
              </h4>
              <address className={`text-xl mb-4`}>
                11 Chalfont Walk
                <br />
                Norvic Drive
                <br />
                Norwich
                <br />
                NR4 7NH
              </address>
              <TextLink
                text={"Get Directions"}
                href={
                  "https://www.google.co.uk/maps/dir//Rowe+Adrian,+15+Chalfont+Walk,+Norwich+NR4+7NH/@52.613951,1.2485911,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47d9e1324d9a54ef:0xd007d4faae2010d3!2m2!1d1.2507775!2d52.6139421!3e0"
                }
                className={"text-xl"}
                target={"_blank"}
              />
            </div>

            {/* Opening Times */}
            <div className="md:w-5/12" data-aos={"fade"} data-aos-delay={"400"}>
              <h3 className={`text-3xl text-black font-bold uppercase mb-4`}>
                Salon Opening Times<span className={`text-red`}>.</span>
              </h3>

              <dl className={`font-light uppercase space-y-2`}>
                {openingTimes.map(weekday => (
                  <div
                    className={`flex flex-row justify-between border-b border-gray-50 pb-2`}
                  >
                    <dt>{weekday.day}</dt>
                    <dd>{weekday.time}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LocationOpeningTimes
