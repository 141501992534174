import * as React from "react";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import HomeHero from '../components/HomeHero';
import HomeIntro from "../components/HomeIntro";
import HomeBlocks from "../components/HomeBlocks";
import LocationOpeningTimes from "../components/LocationOpeningTimes";

const IndexPage = () => (
  <Layout>
    <Seo title="Hair Salon in Eaton, Norwich" />

    <HomeHero/>
    <HomeIntro/>
    <HomeBlocks/>
    <LocationOpeningTimes/>

  </Layout>
)

export default IndexPage
